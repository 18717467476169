import { Variant, ConfigStore } from "@src/stores/config";

import FlipMove from "react-flip-move";

import React from "react";
import { SUBSCRIPTION_TYPE } from "@src/constants";

interface TOSProps {
  config: ConfigStore;
  subscriptionType: SUBSCRIPTION_TYPE;
  paymentType: "invoice" | "cc";
}

export const TOS: React.FunctionComponent<TOSProps> = props => {
  const { config, subscriptionType } = props;
  const noticeDays = "forty-five (45)";
  let autoRenew = config.priceConfig.autoRenewAnnual;
  const autoPriceIncrease = config.priceConfig.automaticAnnualPriceIncrease;
  if (
    subscriptionType === "Monthly" &&
    (config.variant === Variant.CHECKOUT ||
      config.variant === Variant.NON_PROFIT ||
      config.variant === Variant.PRODUCT_HUNT)
  ) {
    autoRenew = config.priceConfig.autoRenewMonthly;
  }

  return (
    <FlipMove>
      {props.config.variant !== Variant.EXPAND && (
        <span style={{ display: "block" }}>
          By clicking 'Submit Order' above, you agree to the
          <a
            href="https://sites.google.com/ginger.io/hsh-terms-library"
            target="_blank"
          >
            {" "}
            Headspace Master Services Agreement
          </a>
        </span>
      )}
      {props.paymentType === "invoice" && (
        <span style={{ display: "block" }}>
          Headspace shall invoice Customer in accordance with the relevant
          Statement of Work. Unless otherwise stated in the Statement of Work,
          invoiced charges are due net 30 days from the invoice date. Customer
          is responsible for providing complete and accurate billing and contact
          information to Headspace and notifying Headspace of any changes to
          such information.
        </span>
      )}
      {props.config.variant === Variant.EXPAND && (
        <span style={{ display: "block" }}>
          By clicking 'Submit Order' above, you agree to the
          <a
            href="https://assets.ctfassets.net/v3n26e09qg2r/J67KQWf6lEXAKKRvSYPt9/44ec9b553614cd390926cee6b9ed6c42/20200909_-_Headspace_MSA__Updated__-_PDF_for_web.pdf"
            target="_blank"
          >
            {" "}
            Headspace Master Services Agreement
          </a>
        </span>
      )}
      {autoRenew && autoPriceIncrease && (
        <span style={{ display: "block", marginTop: 5 }}>
          At each successive renewal term, the monthly/unit price outlined above
          may increase by no more than 5%, subject to the complete discretion of
          Headspace.
          <br />
          <br />
          Need an invoice? Reach out to your Client Success Manager or to&nbsp;
          <a target="_blank" href="mailto: teamsupport@headspace.com">
            teamsupport@headspace.com
          </a>
        </span>
      )}
      {autoRenew && !autoPriceIncrease && (
        <span style={{ display: "block", marginTop: 5 }}>
          Need an invoice? Reach out to your Client Success Manager or to&nbsp;
          <a target="_blank" href="mailto: teamsupport@headspace.com">
            teamsupport@headspace.com
          </a>
        </span>
      )}
    </FlipMove>
  );
};
