import { Col } from 'antd';
import * as React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { SUBSCRIPTION_TYPE } from '@src/constants';
import { CheckoutFormComponent } from './checkoutForm/CheckoutForm';
import { CurrencyType } from '@src/services/pricing';

interface StripeIntegrationProps {
  onPayWithInvoice?: () => void;
  subscriptionType: SUBSCRIPTION_TYPE;
  subQuantity: number;
  subDuration?: number;
  subCurrency: CurrencyType;
  subDiscountCode: string;
  totalPrice: number;
}

export class StripeIntegration extends React.Component<StripeIntegrationProps> {
  public render() {
    const {props: {subCurrency, subDuration, subQuantity, subDiscountCode, subscriptionType}} = this;

    return (
        <Col md={{span: 20, offset: 2}} lg={{span: 10, offset: 0}}>
          <StripeProvider apiKey={STRIPE_API_KEY}>
            <Elements>
            <CheckoutFormComponent
              onPayWithInvoice={this.props.onPayWithInvoice}
              totalPrice={this.props.totalPrice}
              subscriptionType={subscriptionType}
              subQuantity={subQuantity}
              subDuration={subDuration}
              subCurrency={subCurrency}
              subDiscountCode={subDiscountCode}
            />
            </Elements>
        </StripeProvider>
      </Col>
    );
  }
}
