import { Col, Row } from "antd";
import * as React from "react";

import emailImg from "@src/assets/images/icon-techniques-loving_kindness.png";
import practicesImg from "@src/assets/images/icon-techniques-resting_awareness.png";
import feedbackImg from "@src/assets/images/icon-techniques-skillful_compassion.png";
import { WithConfigProp, withConfig, Variant } from "@src/stores/config";

interface LaunchProps {}

class Launch extends React.Component<LaunchProps & WithConfigProp, {}> {
  public render() {
    if (
      this.props.config.variant === Variant.EXPAND ||
      this.props.config.variant === Variant.RENEW
    ) {
      return null;
    }
    return (
      <Row>
        <h2 className="align-center big-margin-bottom">Launch</h2>
        <div className="icon-content">
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            className="horiz-icon"
          >
            <div className="align-center except-xs">
              <img src={emailImg} alt="newsletter" className="icon-img" />
              <h3>Tools</h3>
              <p>Launch kit with email templates and printable posters</p>
            </div>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            className="horiz-icon"
          >
            <div className="align-center except-xs">
              <img
                src={practicesImg}
                alt="best practices"
                className="icon-img"
              />
              <h3>Our Experience</h3>
              <p>
                Best practice guidance for launching successfully and
                incentivizing adoption
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            className="horiz-icon"
          >
            <div className="align-center except-xs">
              <img src={feedbackImg} alt="feedback" className="icon-img" />
              <h3>Here to Help</h3>
              <p>End-user access to Headspace Support</p>
            </div>
          </Col>
        </div>
      </Row>
    );
  }
}

export const LaunchComponent = withConfig(Launch);
