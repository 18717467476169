import {  withConfig, WithExpandedConfigProp } from '@src/stores/config';
import { Col, Row } from 'antd';
import * as React from 'react';
import { RenewOrderFormComponent } from './RenewOrderForm';

interface ExpandProps { }

class Subscription extends React.Component<ExpandProps & WithExpandedConfigProp, {}> {

  public render() {

    return (
      <Row id='subscribe' className='tan-theme'>
        <h1>Renew Your Headspace Account</h1>
        {this.renderBody()}
      </Row>
    );
  }

  private renderBody = () => {

    return(
      <React.Fragment>
        <Col span={24}>
          <RenewOrderFormComponent/>
        </Col>
      </React.Fragment>
    );
  }
}

export const RenewComponent = withConfig(Subscription);
