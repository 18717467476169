import { withConfig, WithDealConfigProp } from '@src/stores/config';
import { Col, Row } from 'antd';
import * as React from 'react';
import { DealOrderFormComponent } from './DealOrderForm';

interface ExpandProps { }

class Subscription extends React.Component<ExpandProps & WithDealConfigProp, {}> {

  public render() {

    return (
      <Row id='subscribe' className='tan-theme'>
        <h1>Your Headspace Deal</h1>
        {this.renderBody()}
      </Row>
    );
  }

  private renderBody = () => {

    return (
      <React.Fragment>
        <Col span={24}>
          <DealOrderFormComponent />
        </Col>
      </React.Fragment>
    );
  }
}

export const DealComponent = withConfig(Subscription);
