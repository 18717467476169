import { SUBSCRIPTION_TYPE } from "@src/constants";

import { CurrencyType } from "./pricing";

import { Variant } from "@src/stores/config";
import { requestSfdc } from "./utils";

export interface SubRequest {
  leadId?: string;
  name: string;
  email: string;
  company: string;
  phone: string;
  address: Address;
  type: SUBSCRIPTION_TYPE;
  quantity: number;
  duration?: number;
  discountCode: string;
  currencyCode: CurrencyType;
  stripePaymentToken?: string;
  byInvoice?: boolean;
  taxId?: string;
  variant: Variant;
  totalPrice: number;
  requirePo?: boolean;
  poNumber?: string;
  subscriptionReferenceId?: string;
}

export interface Address {
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
}

export const subscribe = async (req: SubRequest): Promise<void> => {
  return requestSfdc<SubRequest, never>("smb", req);
};
