import * as React from "react";
import * as ReactDOM from "react-dom";
import { Variant, ConfigProvider, initConfigStore } from "./stores/config";
import App from "./app";
import ErrorComponent from "./components/static/error";

// add custom stylesheet
// tslint:disable-next-line:no-var-requires
require("@src/styles/styles.less");

// temp for testing different variants
import * as queryString from "query-string";

const qryParams = queryString.parse(location.search);
// let variant = qryParams.variant as Variant;
// variant = variant || Variant.CHECKOUT;

let variant = Variant.CHECKOUT;

let recordId;


// if (location.pathname.toLowerCase().includes("nonprofit")) {
//   variant = Variant.NON_PROFIT;
// } else if (location.pathname.toLowerCase().includes("pilot")) {
//   variant = Variant.PILOT;
// } else if (location.pathname.toLowerCase().includes("annual")) {
//   variant = Variant.ANNUAL;
// } else if (location.pathname.toLowerCase().includes("producthunt")) {
//   variant = Variant.PRODUCT_HUNT;
// } else if (
//   location.pathname.toLowerCase().includes("gifts") ||
//   qryParams.gifts
// ) {
//   variant = Variant.ANNUAL;
// } else
if (qryParams.subscriptionId) {
  variant = Variant.EXPAND;
  recordId = qryParams.subscriptionId;
} else if (qryParams.renewalId) {
  variant = Variant.RENEW;
  recordId = qryParams.renewalId;
} else if (qryParams.dealId) {
  variant = Variant.DEAL;
  recordId = qryParams.dealId;
}

initConfigStore(variant, recordId)
  .then(configStore => {
    ReactDOM.render(
      <ConfigProvider value={configStore}>
        <App />
      </ConfigProvider>,
      document.getElementById("root")
    );
  })
  .catch(e => {
    console.log(e);
    ReactDOM.render(<ErrorComponent />, document.getElementById("root"));
  });
