import { DealerLineItem } from '@src/services/pricing';
import { Row, Col } from 'antd';
import React from 'react';
import NumberFormat from 'react-number-format';

interface LineItemTableProps {
  items: DealerLineItem[];
  symbol: string;
}

interface Column<T> {
  span: number;
  header: string;
  render: (item: T) => React.ReactNode;
}

const hStyle: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: 15,
};

export function LineItemTable(props: LineItemTableProps) {

  const columns: Array<Column<DealerLineItem>> = [
    {
      span: 7,
      header: 'Name',
      render: (item) => (item.name),
    },
    {
      span: 4,
      header: 'Billing Type',
      render: (item) => <span style={{textTransform: 'capitalize'}}>{item.revenueType}</span>,
    },
    {
      span: 3,
      header: 'Sale Price',
      render: (item) => (<NumberFormat displayType='text' prefix={props.symbol} thousandSeparator={true} decimalScale={2} value={item.price} />),
    },
    {
      span: 3,
      header: 'Quantity',
      render: (item) => (item.quantity),
    },
    {
      span: 3,
      header: 'Total',
      render: (item) => (<NumberFormat displayType='text' prefix={props.symbol} thousandSeparator={true} decimalScale={2} value={item.quantity * item.price} />),
    },
  ];

  const tableItems = props.items.map((item, liIndex) => {
    return (
      <Row key={liIndex} type='flex' justify="space-between" >
        {columns.map(
          (col, i) => <Col key={i} span={col.span}>{col.render(item)}</Col>)}
      </Row>
    );
  });

  return (
    <div>
      <Row type='flex' justify="space-between" align='middle'>
        {columns.map(
          (col, i) => <Col key={i} span={col.span} style={hStyle}>{col.header}</Col>)}
      </Row>
      {tableItems}
    </div>
  );
}
