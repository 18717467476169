import { Col, Row } from "antd";
import * as React from "react";
import { Variant, withConfig, WithConfigProp } from "@src/stores/config";

interface IntroProps {}

class Intro extends React.Component<IntroProps & WithConfigProp, {}> {
  public render() {
    if (
      this.props.config.variant === Variant.EXPAND ||
      this.props.config.variant === Variant.RENEW
    ) {
      return null;
    }
    return (
      <Row className="orange-theme">
        <Col
          xs={{ offset: 0, span: 24 }}
          lg={{ offset: 4, span: 16 }}
          className="align-center"
        >
          <div className="paragraph-content">
            <h1>{this.getHeaderText()}</h1>
            <p className="large-text">{this.getCopy()}</p>
          </div>
        </Col>
      </Row>
    );
  }

  // [Consideration: Instead of switching in component, these values could be defined on global objects which we just key off the variant.
  //  Pros: No redundant switch code, easier to update, lays foundation for moving to SF backed configuration
  //  Cons: Harder to diverge/extend in new directions for different variants, could morph into a lightweight CMS]
  public getHeaderText = () => {
    switch (this.props.config.variant) {
      case Variant.NON_PROFIT:
        return "Headspace for Nonprofits";
      case Variant.PRODUCT_HUNT:
        return "Headspace for Product Hunt";
      default:
        return "Headspace";
    }
  };

  public getCopy = () => {
    switch (this.props.config.variant) {
      case Variant.NON_PROFIT:
        return `Headspace for Nonprofits is designed to help you successfully launch Headspace,
                engage your employees and volunteers, and measure impact. We offer easily accessible
                resources to support your organization’s Headspace goals.`;
      default:
        return `Our program is designed to help you successfully launch Headspace, engage your employees, and measure impact. We offer easily accessible resources to support your Headspace goals.`;
    }
  };
}

export const IntroComponent = withConfig(Intro);
