import Axios from "axios";
import { string } from 'prop-types';

export interface SuccessRequest<T> {
  success: true;
  result: T;
}

export interface FailedRequest {
  success: false;
  message: string;
}

export interface StandardSFError {
  errorCode: string;
  message: string;
}

export interface SfdxRequestOptions {
  data?: object;
  params?: {[key: string]: any};
}

/**
 * Helper for making salesforce requests
 * @param opt: SfdxRequestOptions containing body and param data
 */
export const requestSfdc = async <T, R>(endpoint: string, data: T): Promise<R>  => {
  const response = await Axios.post<SuccessRequest<R> | FailedRequest | StandardSFError[]>(
    `/purchase/services/apexrest/${endpoint}`,
    data,
    {
      // do our own custom parsing based on the body
      validateStatus: (status) => status >= 200,
    },
  );

  if (!response.data) {
    throw new Error('Unexpected Error Occurred.  Please Contact Support');
  } else if (Array.isArray(response.data)) { // standard SF error
    throw new Error(response.data[0].message);
  } else {
    if (response.data.success === true) {
      return response.data.result;
    } else {
      throw new Error(response.data.message);
    }
  }
};
