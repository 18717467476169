// put exports here
export * from './static';
export * from './checkout';

import FlipMove from 'react-flip-move';
import classNames from 'classnames';

(FlipMove as any).defaultProps.maintainContainerHeight = true;
(FlipMove as any).defaultProps.enterAnimation = "fade";
(FlipMove as any).defaultProps.leaveAnimation = "fade";
(FlipMove as any).defaultProps.appearAnimation = "fade";

import PhoneInput from 'react-phone-input-2';
export {FlipMove, classNames, PhoneInput};
