import { classNames, FlipMove } from "@src/components";
import { StripeIntegration } from "../shared";
import { Discount } from "@src/services/pricing";
import { withConfig, WithExpandedConfigProp } from "@src/stores/config";
import { Col, InputNumber, Row } from "antd";
import * as React from "react";
import { DiscountCodeComponent } from "../shared/DiscountCode";
import * as css from "../shared/OrderForm.pcss";

interface OrderFormProps {}

interface OrderState {
  discount?: Discount;
  seats: number;
}

// tslint:disable-next-line:max-classes-per-file
class OrderForm extends React.Component<
  OrderFormProps & WithExpandedConfigProp,
  OrderState
> {
  constructor(props: OrderFormProps & WithExpandedConfigProp) {
    super(props);

    this.state = {
      discount: null,
      seats: props.config.priceConfig.minimumSeats
    };
  }

  private updateDiscount = (discount: Discount) => {
    this.setState({ discount });
  };

  private updateQuantity = (v: number) => {
    if (v) {
      this.setState({ seats: v });
    }
  };

  public render() {
    const {
      state: { seats, discount },
      props: {
        config: {
          priceConfig: {
            price: { currencyCode: currency, symbol }
          }
        }
      }
    } = this;

    const minSeats = this.props.config.priceConfig.minimumSeats || 30;
    const discountCode = discount ? discount.code : null;
    return (
      <Row
        id="checkoutForm"
        className={classNames(css.root, "form-row")}
        gutter={48}
      >
        <Col md={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
          <h3>Your order summary</h3>
          <h4 className="top">SUBSCRIPTION</h4>
          <FlipMove>
            <h4 className="middle">
              Company
              <span className="align-right">
                {this.props.config.priceConfig.companyName}
              </span>
            </h4>
            <h4 className="middle">
              Licenses Purchased
              <span className="align-right">
                {this.props.config.priceConfig.currentSeats}
              </span>
            </h4>
            <h4 className="middle">
              End of Term
              <span className="align-right">
                {this.props.config.priceConfig.endOfTerm}
              </span>
            </h4>
            <h4 className="middle">
              Cost Per Seat (prorated based on remaining term)
              <span className="align-right">
                {symbol}
                {this.props.config.priceConfig.price.pricePerSeat}
              </span>
            </h4>
            <h4 key="quantity" className="middle">
              Quantity
              <span>
                <InputNumber
                  size="small"
                  min={minSeats}
                  max={100000}
                  step={1}
                  value={seats}
                  onChange={this.updateQuantity}
                />
                <span className={css.text}>employees</span>
              </span>
            </h4>
            <h4 key="total" className="bottom">
              TOTAL
              <span>
                {symbol}
                {this.subTotal().toFixed(2)}
              </span>
            </h4>
          </FlipMove>
          <p className="fine-print">
            Applicable VAT, sales or other applicable taxes may apply.
          </p>
        </Col>

        <StripeIntegration
          subscriptionType="Expand"
          subQuantity={seats}
          subCurrency={currency}
          subDiscountCode={discountCode}
          totalPrice={this.subTotal()}
        />
      </Row>
    );
  }

  private subTotal = () => {
    const { seats, discount } = this.state;
    const price = this.props.config.priceConfig.price.pricePerSeat;

    let subTotal = price * seats;

    if (discount) {
      switch (discount.type) {
        case "FIXED":
          subTotal = subTotal - discount.amount;
          break;
        case "PERCENT":
          subTotal = subTotal * (1 - discount.amount / 100);
          break;
        case "PER_SEAT":
          subTotal = subTotal - discount.amount * seats;
          break;
      }
    }
    return Math.round(subTotal * 100) / 100;
  };
}

export const ExpandOrderFormComponent = withConfig(OrderForm);
