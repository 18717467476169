import * as React from "react";
import {
  getPriceData,
  StandardPriceConfig,
  ExpandPriceConfig,
  CurrencyType,
  getExpandPriceData,
  DealerPriceConfig,
  getDealPriceData
} from "@src/services/pricing";

export enum Variant {
  CHECKOUT = "CHECKOUT",
  NON_PROFIT = "NON_PROFIT",
  PILOT = "PILOT",
  ANNUAL = "ANNUAL",
  EXPAND = "EXPAND",
  RENEW = "RENEW",
  PRODUCT_HUNT = "PRODUCT_HUNT",
  DEAL = "DEAL"
}

export interface StandardConfigStore {
  variant:
    | Variant.CHECKOUT
    | Variant.NON_PROFIT
    | Variant.PILOT
    | Variant.ANNUAL
    | Variant.PRODUCT_HUNT;
  priceConfig?: StandardPriceConfig;
}

export interface DealConfigStore {
  variant: Variant.DEAL;
  priceConfig?: DealerPriceConfig;
}

export interface ExpandConfigStore {
  variant: Variant.EXPAND | Variant.RENEW;
  priceConfig?: ExpandPriceConfig;
}

export type ConfigStore =
  | StandardConfigStore
  | ExpandConfigStore
  | DealConfigStore;

export const initConfigStore = async (
  variant: Variant,
  subscriptionReferenceId?: string
): Promise<ConfigStore> => {
  if (variant === Variant.EXPAND || variant === Variant.RENEW) {
    return {
      variant,
      priceConfig: await getExpandPriceData({
        subscriptionReferenceId,
        variant
      })
    };
  } else if (variant === Variant.DEAL) {
    return {
      variant,
      priceConfig: await getDealPriceData({ subscriptionReferenceId, variant })
    };
  } else {
    return {
      variant,
      priceConfig: await getPriceData({ variant })
    };
  }
};

export const { Provider: ConfigProvider, Consumer } = React.createContext<
  ConfigStore
>({ variant: Variant.CHECKOUT });

export interface WithStandardConfigProp {
  config: StandardConfigStore;
}

export interface WithExpandedConfigProp {
  config: ExpandConfigStore;
}

export interface WithDealConfigProp {
  config: DealConfigStore;
}

export type WithConfigProp =
  | WithStandardConfigProp
  | WithExpandedConfigProp
  | WithDealConfigProp;

export const withConfig = <P extends any>(
  Component: React.ComponentType<P & WithConfigProp>
) =>
  class WithStore extends React.Component<P> {
    public render() {
      return (
        <Consumer>
          {config => {
            const propsWithStore = { ...this.props, ...{ config } } as any;
            return <Component {...propsWithStore} />;
          }}
        </Consumer>
      );
    }
  };
