import { classNames, FlipMove } from "@src/components";
import { SUBSCRIPTION_TYPE } from "@src/constants";
import { CurrencyType, Discount } from "@src/services/pricing";
import {
  withConfig,
  WithStandardConfigProp,
  Variant
} from "@src/stores/config";
import { Col, InputNumber, Row, Tooltip } from "antd";
import * as React from "react";
import { DiscountCodeComponent, StripeIntegration } from "../shared";
import * as css from "../shared/OrderForm.pcss";

interface OrderFormProps {
  subscriptionType: SUBSCRIPTION_TYPE;
  symbol: string;
  price: number;
  currency: CurrencyType;
  handleCurrencyChange: (e) => void;
}

interface OrderState {
  discount?: Discount;
  seats: number;
  term: number;
}

// tslint:disable-next-line:max-classes-per-file
class OrderForm extends React.Component<
  OrderFormProps & WithStandardConfigProp,
  OrderState
> {
  constructor(props: OrderFormProps & WithStandardConfigProp) {
    super(props);

    this.state = {
      discount: null,
      seats: props.config.priceConfig.defaultSeats,
      term: 1
    };

    console.log(props.config.priceConfig);
  }

  private updateDiscount = (discount: Discount) => {
    this.setState({ discount });
  };

  private updateTerm = (term: number) => {
    if (term) {
      this.setState({ term });
    }
  };

  private updateQuantity = (v: number) => {
    if (v) {
      this.setState({ seats: v });
    }
  };

  public render() {
    const {
      state: { term, seats, discount },
      props: { handleCurrencyChange, subscriptionType, price, currency, symbol }
    } = this;

    const minSeats = () => {
      let min;
      if (subscriptionType === "Monthly") {
        min = this.props.config.priceConfig.monthlyMinSeats || 30;
      } else {
        min = this.props.config.priceConfig.minimumSeats || 30;
      }
      return min;
    };
    const maxDuration = this.props.config.priceConfig.maximumDuration || 100000;

    const discountCode = discount ? discount.code : null;
    return (
      <Row
        id="checkoutForm"
        className={classNames(css.root, "form-row")}
        gutter={48}
      >
        <Col md={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
          <h3>Your order summary</h3>
          <h4 className="top">
            SUBSCRIPTION
            <span className="align-right">
              <span className="select-wrapper">
                <select onChange={handleCurrencyChange} value={currency}>
                  {Object.keys(this.props.config.priceConfig.priceLists).map(
                    c => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    )
                  )}
                </select>
              </span>
            </span>
          </h4>
          <FlipMove>
            <h4 className="middle">
              {subscriptionType}
              <span className="align-right">
                {symbol}
                {price}
              </span>
            </h4>
            <h4 key="quantity" className="middle">
              Quantity
              <Tooltip
                placement="left"
                title={"*Minimum Quantity of " + minSeats()}
              >
                <span>
                  <InputNumber
                    size="small"
                    min={minSeats()}
                    max={100000}
                    step={1}
                    value={seats}
                    onChange={this.updateQuantity}
                  />
                  <span className={css.text}>employees</span>
                </span>
              </Tooltip>
            </h4>
            {(subscriptionType === "Monthly" ||
              subscriptionType === "Weekly") && (
              <h4 key="term" className="middle">
                Term Duration
                <span>
                  <InputNumber
                    size="small"
                    min={1}
                    max={maxDuration}
                    step={1}
                    value={term}
                    onChange={this.updateTerm}
                  />
                  {subscriptionType === "Monthly" && (
                    <span className={css.text}>
                      month
                      {term > 1 ? "s" : ""}
                    </span>
                  )}
                  {subscriptionType === "Weekly" && (
                    <span className={css.text}>
                      week
                      {term > 1 ? "s" : ""}
                    </span>
                  )}
                </span>
              </h4>
            )}
            <h4 key="total" className="bottom">
              TOTAL
              <span>
                {symbol}
                {this.subTotal().toFixed(2)}
              </span>
            </h4>
          </FlipMove>
          <p className="small-text order">{this.subDescr()}</p>
          <p className="fine-print">
            Applicable VAT, sales or other applicable taxes may apply.
          </p>
        </Col>

        <StripeIntegration
          subscriptionType={subscriptionType}
          subQuantity={seats}
          subDuration={term}
          subCurrency={currency}
          subDiscountCode={discountCode}
          totalPrice={this.subTotal()}
        />
      </Row>
    );
  }

  private subDescr = () => {
    switch (this.props.subscriptionType) {
      case "Annual":
        return "Unlimited access to the Headspace Library for 12 months";
      case "Monthly":
        return `Unlimited access to the Headspace Library for ${
          this.state.term
        } month${this.state.term > 1 ? "s" : ""}`;
      case "Weekly":
        return `Unlimited access to the Headspace Library for ${
          this.state.term
        } week${this.state.term > 1 ? "s" : ""}`;
      default:
        return "";
    }
  };

  private subTotal = () => {
    const { seats, term, discount } = this.state;
    const { price } = this.props;

    let subTotal =
      this.props.subscriptionType === "Annual"
        ? price * seats
        : price * seats * term;

    if (discount) {
      switch (discount.type) {
        case "FIXED":
          subTotal = subTotal - discount.amount;
          break;
        case "PERCENT":
          subTotal = subTotal * (1 - discount.amount / 100);
          break;
        case "PER_SEAT":
          subTotal = subTotal - discount.amount * seats;
          break;
      }
    }
    return Math.round(subTotal * 100) / 100;
  };
}

export const OrderFormComponent = withConfig(OrderForm);
