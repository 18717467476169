import { hot } from 'react-hot-loader'; // needs to be before react!
import * as React from 'react';
import { HeaderComponent, IntroComponent, LaunchComponent, EngageComponent, MeasureComponent, PurchaseComponent, ExpandComponent, RenewComponent } from '@src/components';
import { WithConfigProp, withConfig, Variant } from './stores/config';
import { DealComponent } from './components/checkout/dealer/DealComponent';

interface AppProps { }

class App extends React.Component<AppProps & WithConfigProp, never> {

  public render() {
    return (
      <div>
        <HeaderComponent />
        <IntroComponent />
        <LaunchComponent />
        <EngageComponent />
        <MeasureComponent />
        {this.renderCheckout()}
      </div>
    );
  }

  private renderCheckout = () => {
    switch (this.props.config.variant) {
      case Variant.EXPAND:
        return <ExpandComponent />;
      case Variant.RENEW:
        return <RenewComponent />;
      case Variant.DEAL:
        return <DealComponent />;
      default:
        return <PurchaseComponent />;
    }
  }
}

export default hot(module)(withConfig(App));

if (module.hot) {
  console.clear();
}
