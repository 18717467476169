import { Col, Row } from "antd";
import * as React from "react";

import measureImg from "@src/assets/images/hs_workplace_2.png";
import { WithConfigProp, withConfig, Variant } from "@src/stores/config";

interface MeasureProps {}

export class Measure extends React.Component<
  MeasureProps & WithConfigProp,
  {},
  WithConfigProp
> {
  public render() {
    if (!this.show()) {
      return null;
    }
    return (
      <Row>
        <div className="icon-content">
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 0 }}>
            <img src={measureImg} alt="engage" style={{ width: "100%" }} />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <div className="paragraph-content offset-top">
              <h2>Measure</h2>
              <ul>
                <li>
                  Quarterly reporting on minutes and sessions meditated, popular
                  packs, and more
                </li>
              </ul>
            </div>
          </Col>
        </div>
      </Row>
    );
  }

  private show = () => {
    if (
      this.props.config.variant === Variant.NON_PROFIT ||
      this.props.config.variant === Variant.EXPAND ||
      this.props.config.variant === Variant.RENEW
    ) {
      return false;
    }
    return true;
  };
}

export const MeasureComponent = withConfig(Measure);
