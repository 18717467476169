import * as React from "react";
import * as css from "./CheckoutForm.pcss";
import { validateEmail, isEmpty } from "@src/utility";
import { Row, Col, Select } from "antd";
import { STATES, CA_PROVINCES } from "@src/constants";
import { classNames, PhoneInput } from "@src/components";
import { withConfig, WithConfigProp, Variant } from "@src/stores/config";

export interface CustomerInfo {
  name: string;
  email: string;
  company: string;
  taxId?: string;
  phone: string;
  countryCode: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface CustomerInfoFormProps extends CustomerInfo {
  disable: boolean;
  onChange: <K extends keyof CustomerInfo, V = CustomerInfo[K]>(
    key: keyof CustomerInfo,
    val: V
  ) => void;
  onCompleteChange: (complete: boolean) => void;
  visited: { [P in keyof CustomerInfo]?: boolean };
  onVisited: (key: keyof CustomerInfo) => void;
  showState: boolean;
}

export class CustomerInfoForm extends React.Component<
  CustomerInfoFormProps & WithConfigProp
> {
  constructor(props: CustomerInfoFormProps & WithConfigProp) {
    super(props);
    this.state = {
      visited: {}
    };
  }

  public inputCity: HTMLInputElement;
  public selectState: Select;

  private handleInputChange<
    K extends keyof CustomerInfo,
    V extends CustomerInfo[K]
  >(key: keyof CustomerInfo, val: V) {
    this.props.onChange(key, val);
  }

  public componentDidUpdate = (prevProps: CustomerInfoFormProps) => {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.props.onCompleteChange(!this.hasError());
    }
  };

  private onChangeCheckout = e => {
    this.handleInputChange(e.target.name, e.target.value);
  };

  private nameError = () => {
    return isEmpty(this.props.name);
  };

  private emailError = () => {
    return !validateEmail(this.props.email);
  };

  private companyError = () => {
    return isEmpty(this.props.company);
  };

  private taxIdError = () => {
    return (
      this.props.config.priceConfig.taxIdRequired && isEmpty(this.props.taxId)
    );
  };

  private phoneError = () => {
    return !this.props.phone;
  };

  private streetError = () => {
    return isEmpty(this.props.street);
  };

  private cityError = () => {
    return isEmpty(this.props.city);
  };

  private stateError = () => {
    return this.props.showState && isEmpty(this.props.state);
  };

  private zipError = () => {
    return isEmpty(this.props.zipcode);
  };

  private setupInput = (
    key: keyof CustomerInfo,
    element: React.ReactElement<any>,
    validation?: () => boolean
  ) => {
    const addProps: any = {
      value: this.props[key],
      name: key,
      id: key,
      disabled: this.props.disable,
      onBlur: () => {
        this.props.onVisited(key);
      }
    };
    if (validation) {
      addProps.className = classNames({
        [css.error]: this.props.visited[key] && validation()
      });
    }

    return React.cloneElement(element, addProps);
  };

  public hasError = () => {
    const {
      nameError,
      emailError,
      zipError,
      cityError,
      stateError,
      streetError,
      companyError,
      phoneError,
      taxIdError
    } = this;

    return (
      nameError() ||
      emailError() ||
      zipError() ||
      cityError() ||
      stateError() ||
      streetError() ||
      companyError() ||
      phoneError() ||
      taxIdError()
    );
  };

  public render() {
    const { disable } = this.props;

    const nameInput = this.setupInput(
      "name",
      <input
        onChange={this.onChangeCheckout}
        placeholder="Name"
        required={true}
      />,
      this.nameError
    );

    const emailInput = this.setupInput(
      "email",
      <input
        onChange={this.onChangeCheckout}
        placeholder="E-Mail"
        required={true}
      />,
      this.emailError
    );

    const companyInput = this.setupInput(
      "company",
      <input
        onChange={this.onChangeCheckout}
        placeholder="Company"
        required={true}
      />,
      this.companyError
    );

    const phoneInput = this.setupInput(
      "phone",
      <PhoneInput
        placeholder="Phone #"
        disableAreaCodes={true}
        defaultCountry={"us"}
        preferredCountries={["ca", "fr", "gb", "de", "us"]}
        regions={[
          "america",
          "europe",
          "asia",
          "oceania",
          "africa",
          "middle-east"
        ]}
        enableLongNumbers={true}
        onChange={(p, c) => {
          this.handleInputChange("phone", p);
          this.handleInputChange("countryCode", c.countryCode);
        }}
      />
    );

    const taxIdInput = this.setupInput(
      "taxId",
      <input
        onChange={this.onChangeCheckout}
        placeholder="Tax ID/Charity Number"
        required={true}
      />,
      this.taxIdError
    );

    const streetInput = this.setupInput(
      "street",
      <input
        onChange={this.onChangeCheckout}
        placeholder="Street Address"
        required={true}
      />,
      this.streetError
    );

    const cityInput = this.setupInput(
      "city",
      <input
        ref={r => (this.inputCity = r)}
        onChange={this.onChangeCheckout}
        placeholder="City"
        required={true}
      />,
      this.cityError
    );

    const rcProps = {
      showAction: ["click", "focus"]
    };
    const stateInput =
      this.props.countryCode === "ca"
        ? this.setupInput(
            "state",
            <Select
              {...rcProps}
              ref={r => (this.selectState = r)}
              style={{ height: 40 }}
              className={classNames(css.state, {
                [css.error]: this.props.visited["state"] && this.stateError()
              })}
              dropdownClassName={css["state-dropdown"]}
              onSelect={(o: string) => this.handleInputChange("state", o)}
              showSearch={true}
              placeholder="Province"
              onInputKeyDown={e => {
                // Hacky but it works.  If we don't do this the shift-tab gets eaten by the dropdown selection
                // if we immediately focus the city textbox then on key up the shift-tab fires again and it'll jump to street address.
                if (e.shiftKey && e.keyCode === 9) {
                  setTimeout(() => this.inputCity.focus(), 0);
                }
              }}
            >
              {CA_PROVINCES.map((s, i) => (
                <Select.Option value={s} key={i}>
                  {s}
                </Select.Option>
              ))}
            </Select>
          )
        : this.setupInput(
            "state",
            <Select
              {...rcProps}
              ref={r => (this.selectState = r)}
              style={{ height: 40 }}
              className={classNames(css.state, {
                [css.error]: this.props.visited["state"] && this.stateError()
              })}
              dropdownClassName={css["state-dropdown"]}
              onSelect={(o: string) => this.handleInputChange("state", o)}
              showSearch={true}
              placeholder="State"
              onInputKeyDown={e => {
                // Hacky but it works.  If we don't do this the shift-tab gets eaten by the dropdown selection
                // if we immediately focus the city textbox then on key up the shift-tab fires again and it'll jump to street address.
                if (e.shiftKey && e.keyCode === 9) {
                  setTimeout(() => this.inputCity.focus(), 0);
                }
              }}
            >
              {STATES.map((s, i) => (
                <Select.Option value={s} key={i}>
                  {s}
                </Select.Option>
              ))}
            </Select>
          );

    const zipInput = this.setupInput(
      "zipcode",
      <input
        ref={r => (this.inputCity = r)}
        onChange={this.onChangeCheckout}
        placeholder="Postal Code"
        required={true}
      />,
      this.zipError
    );

    return (
      <React.Fragment>
        <Row className="form-row" gutter={16}>
          <Col span={12}>{nameInput}</Col>
          <Col span={12}>{emailInput}</Col>
        </Row>
        <Row className="form-row" gutter={16}>
          <Col span={12}>{companyInput}</Col>
          <Col span={12}>
            <div
              className={classNames(css.phone, {
                [css.error]: this.props.visited["phone"] && this.phoneError()
              })}
              aria-disabled={disable}
            >
              {phoneInput}
            </div>
          </Col>
        </Row>

        {this.props.config.priceConfig.taxIdRequired && (
          <Row className="form-row" gutter={16}>
            <Col span={24}>{taxIdInput}</Col>
          </Row>
        )}

        <Row className="form-row" gutter={16}>
          <Col span={24}>{streetInput}</Col>
        </Row>

        <Row className="form-row" gutter={16}>
          <Col span={12}>{cityInput}</Col>
          {this.props.showState && <Col span={6}>{stateInput}</Col>}
          <Col span={this.props.showState ? 6 : 12}>{zipInput}</Col>
        </Row>
      </React.Fragment>
    );
  }
}

export const CustomerInfoFormComponent = withConfig(CustomerInfoForm);
