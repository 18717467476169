import { Checkbox, Col, Icon, Row, Input } from "antd";
import * as css from "./CheckoutForm.pcss";
import { classNames, FlipMove } from "@src/components";
import * as React from "react";
import * as queryString from "query-string";
import { SUBSCRIPTION_TYPE } from "@src/constants";
import { Variant, withConfig, WithConfigProp } from "@src/stores/config";
import { CurrencyType } from "@src/services/pricing";
import { SubRequest, subscribe } from "@src/services/subscribe";
import { CustomerInfo, CustomerInfoFormComponent } from "./CustomerInfoForm";
import { TOS } from "../tos";

interface CheckoutFormProps {
  subscriptionType: SUBSCRIPTION_TYPE;
  subQuantity: number;
  subDuration?: number;
  subDiscountCode: string;
  subCurrency: CurrencyType;
  totalPrice: number;
  onPayWithCard: () => void;
}

interface CheckoutFormState extends CustomerInfo {
  requirePo: boolean;
  poNumber: string;
  customerInfoComplete: boolean;
  errorMessage: string;
  isSubmittingOrder: boolean;
  visited: { [P in (keyof CustomerInfo) | "stripe"]?: boolean };
  orderPlaced: boolean;
}

class CheckoutForm extends React.Component<
  CheckoutFormProps & WithConfigProp,
  CheckoutFormState
> {
  constructor(props: CheckoutFormProps & WithConfigProp) {
    super(props);
    this.state = {
      requirePo: false,
      poNumber: undefined,
      name: "",
      email: "",
      company: "",
      phone: "",
      taxId: "",
      state: undefined,
      zipcode: "",
      city: "",
      street: "",
      countryCode: "us",
      customerInfoComplete: false,
      isSubmittingOrder: false,
      visited: {},
      errorMessage: null,
      orderPlaced: false
    };
  }

  public toggleRequirePo = () => {
    this.setState({ requirePo: !this.state.requirePo });
  };

  public setVisited = (key: (keyof CustomerInfo) | "stripe") => {
    this.setState({
      visited: { ...this.state.visited, ...{ [key]: true } }
    });
  };

  public setCustomerInfo = <K extends keyof CustomerInfo, V = CustomerInfo[K]>(
    key: keyof CustomerInfo,
    val: V
  ) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: val
    }));
  };

  public submitOrder = async () => {
    const {
      props: { subCurrency },
      state: { name, city, state, street, zipcode }
    } = this;

    // mark all as visited so all errors show up
    if (!this.state.customerInfoComplete) {
      this.setState({
        visited: {
          name: true,
          email: true,
          company: true,
          taxId: true,
          street: true,
          city: true,
          state: true,
          zipcode: true,
          phone: true,
          stripe: true
        }
      });
      return;
    }

    if (this.state.isSubmittingOrder || this.state.orderPlaced) {
      return;
    }

    this.setState(
      {
        isSubmittingOrder: true,
        errorMessage: null
      },
      async () => {
        try {
          const {
            state: { email, company, phone },
            props: {
              subscriptionType,
              subQuantity,
              subDuration,
              subDiscountCode
            }
          } = this;

          const reqData: SubRequest = {
            leadId: queryString.parse(location.search).id,
            name,
            email,
            company,
            phone,
            address: {
              street,
              city,
              state,
              zipcode,
              countryCode: this.state.countryCode
            },
            type: subscriptionType,
            quantity: subQuantity,
            duration: subscriptionType === "Annual" ? 12 : subDuration,
            currencyCode: subCurrency,
            byInvoice: true,
            discountCode: subDiscountCode,
            totalPrice: this.props.totalPrice,
            variant: this.props.config.variant,
            taxId: this.state.taxId,
            requirePo: this.state.requirePo,
            poNumber: this.state.poNumber,
            subscriptionReferenceId:
              this.props.config.variant === Variant.EXPAND ||
              this.props.config.variant === Variant.RENEW
                ? this.props.config.priceConfig.subscriptionReferenceId
                : null
          };

          await subscribe(reqData);
          this.setState({
            orderPlaced: true
          });
        } catch (error) {
          // console.log("Axios Error", error);
          this.setState({
            errorMessage: error.message
              ? error.message
              : "There was an error submitting your request."
          });
        } finally {
          this.setState({ isSubmittingOrder: false });
        }
      }
    );
  };

  public render() {
    const {
      state: { errorMessage, orderPlaced, isSubmittingOrder },
      props: { subscriptionType, config }
    } = this;

    const canSubmitOrder = this.state.customerInfoComplete && !orderPlaced;
    const disableInput = this.state.orderPlaced || this.state.isSubmittingOrder;
    const showState = this.state.countryCode === "us";

    return (
      <Col md={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 0 }}>
        <h3>
          Pay By Invoice{" "}
          <span style={{ float: "right", fontSize: 14 }}>
            <a onClick={this.props.onPayWithCard}>Pay with card</a>
          </span>
        </h3>
        <form className={classNames(`stripe-form`, css.root)}>
          <CustomerInfoFormComponent
            name={this.state.name}
            email={this.state.email}
            company={this.state.company}
            phone={this.state.phone}
            countryCode={this.state.countryCode}
            street={this.state.street}
            city={this.state.city}
            state={this.state.state}
            zipcode={this.state.zipcode}
            taxId={this.state.taxId}
            disable={disableInput}
            visited={this.state.visited}
            onChange={this.setCustomerInfo}
            onVisited={this.setVisited}
            onCompleteChange={complete =>
              this.setState({ customerInfoComplete: complete })
            }
            showState={showState}
          />
          <Row className={classNames("form-row", css["gift-row"])}>
            <FlipMove>
              <Col
                span={12}
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexWrap: "nowrap"
                }}
              >
                <Checkbox
                  disabled={disableInput}
                  checked={this.state.requirePo}
                  onChange={this.toggleRequirePo}
                >
                  Require PO
                </Checkbox>
              </Col>
              {this.state.requirePo && (
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    flexWrap: "nowrap"
                  }}
                >
                  <input
                    value={this.state.poNumber}
                    onChange={e => this.setState({ poNumber: e.target.value })}
                    placeholder="PO Number"
                  />
                </Col>
              )}
            </FlipMove>
          </Row>
          <Row className={classNames("form-row")}>
            <Col span={24}>
              <a
                className={classNames(css["order-button"], subscriptionType, {
                  [css["order-placed"]]: orderPlaced
                })}
                aria-disabled={!canSubmitOrder}
                onClick={this.submitOrder}
              >
                {orderPlaced ? "Your Order has Been Placed!" : "Submit Order"}
                {isSubmittingOrder && (
                  <Icon type="sync" className={css.loader} spin={true} />
                )}
              </a>
            </Col>
            <Col span={24}>
              <FlipMove>
                {errorMessage && (
                  <span className={css.errorMessage}>{errorMessage}</span>
                )}
              </FlipMove>
            </Col>
            <Col span={24}>
              <TOS
                paymentType="invoice"
                config={this.props.config}
                subscriptionType={subscriptionType}
              />
            </Col>
          </Row>
        </form>
      </Col>
    );
  }
}

export const InvoiceForm = withConfig(CheckoutForm);
