import { Variant } from "@src/stores/config";
import { requestSfdc } from "./utils";

export enum CurrencyType {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR"
}

export interface PriceData {
  symbol: string;
  annualPricePerSeat: number;
  monthlyPricePerSeat: number;
  weeklyPricePerSeat: number;
}

export interface ExpandPriceDataRequest {
  subscriptionReferenceId: string;
  variant: Variant;
}

export interface ExpandPriceConfig {
  companyName: string;
  currentSeats: number;
  endOfTerm: string;
  startOfTerm?: string;
  minimumSeats: number;
  originalVariant: Variant;
  subscriptionReferenceId: string;
  discountCodesAllowed: boolean;
  price: {
    currencyCode: CurrencyType;
    symbol: string;
    pricePerSeat: number;
  };
  autoRenewAnnual: boolean;
  automaticAnnualPriceIncrease: boolean;
  taxIdRequired: boolean;
}

export interface DealerPriceConfig {
  companyName: string;
  subscriptionReferenceId: string;
  endOfTerm: string;
  startOfTerm?: string;
  currencyInfo: {
    code: CurrencyType;
    symbol: string;
  };
  autoRenewAnnual: boolean;
  automaticAnnualPriceIncrease: boolean;
  taxIdRequired: boolean;
  lineItems: DealerLineItem[];
}

export interface DealerLineItem {
  id: string;
  name: string;
  revenueType: "recurring" | "one-time";
  price: number;
  quantity: number;
}

/**
 * Get pricing information for contract expansion
 */
export const getExpandPriceData = async (
  req: ExpandPriceDataRequest
): Promise<ExpandPriceConfig> => {
  return requestSfdc<ExpandPriceDataRequest, ExpandPriceConfig>("add", req);
};

/**
 * Get pricing information for contract deal
 */
export const getDealPriceData = async (
  req: ExpandPriceDataRequest
): Promise<DealerPriceConfig> => {
  return requestSfdc<ExpandPriceDataRequest, DealerPriceConfig>("deal", req);
};

export interface PriceDataRequest {
  variant: Variant;
}

export interface StandardPriceConfig {
  maximumDuration: number;
  minimumSeats: number;
  monthlyMinSeats: number;
  defaultSeats: number;
  discountCodesAllowed: boolean;
  taxIdRequired: boolean;
  autoRenewAnnual: boolean;
  autoRenewMonthly: boolean;
  automaticAnnualPriceIncrease: boolean;
  priceLists: { [P in CurrencyType]: PriceData };
}

/**
 * Get standard prices for different currencies
 * @param req: DiscountRequest object
 */
export const getPriceData = async (
  req: PriceDataRequest
): Promise<StandardPriceConfig> => {
  return requestSfdc<PriceDataRequest, StandardPriceConfig>("pricing", req);
};

export interface DiscountRequest {
  leadId?: string;
  email?: string;
  discountCode?: string;
  currency?: CurrencyType;
  variant: Variant;
}

export interface DiscountBase {
  amount: number;
  code: string;
}

export interface PerSeatDiscount extends DiscountBase {
  type: "PER_SEAT";
}

export interface TotalDiscount extends DiscountBase {
  type: "FIXED";
}

export interface PercentOffDiscount extends DiscountBase {
  type: "PERCENT";
}

export type Discount = PerSeatDiscount | TotalDiscount | PercentOffDiscount;

/**
 * Get pricing information for a discount code
 * @param req: DiscountRequest object
 */
export const getDiscountPrice = async (
  req: DiscountRequest
): Promise<Discount> => {
  return requestSfdc<DiscountRequest, Discount>("discount", req);
};
