import { classNames } from "@src/components";
import { StripeIntegration } from "../shared";
import { withConfig, WithDealConfigProp } from "@src/stores/config";
import { Col, Row } from "antd";
import * as React from "react";
import * as css from "../shared/OrderForm.pcss";
import { LineItemTable } from "./LineItemTable";
import NumberFormat from "react-number-format";

interface OrderFormProps {}

// tslint:disable-next-line:max-classes-per-file
class OrderForm extends React.Component<
  OrderFormProps & WithDealConfigProp,
  never
> {
  constructor(props: OrderFormProps & WithDealConfigProp) {
    super(props);
  }

  public render() {
    const {
      props: {
        config: {
          priceConfig: { currencyInfo: currency, lineItems }
        }
      }
    } = this;

    const subTotals = this.subTotal();
    const total = subTotals.recurring + subTotals.onetime;
    const seatQuantity = this.subQuantityCalc().seats;

    return (
      <Row
        id="checkoutForm"
        className={classNames(css.root, "form-row")}
        gutter={48}
      >
        <Col md={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
          <h3>Your order summary</h3>
          <h4 className="top">
            SUBSCRIPTION
            <span className="align-right">{currency.code}</span>
          </h4>

          <h4 className="middle">
            Company
            <span className="align-right">
              {this.props.config.priceConfig.companyName}
            </span>
          </h4>
          <h4 className="middle">
            Start of Term
            <span className="align-right">
              {this.props.config.priceConfig.startOfTerm}
            </span>
          </h4>
          <h4 className="middle">
            End of Term
            <span className="align-right">
              {this.props.config.priceConfig.endOfTerm}
            </span>
          </h4>

          <LineItemTable items={lineItems} symbol={currency.symbol} />

          <h4 className="top" />

          <h4 className="top">
            TOTAL DUE NOW
            <NumberFormat
              displayType="text"
              prefix={currency.symbol}
              thousandSeparator={true}
              decimalScale={2}
              value={total}
            />
            <p className="fine-print">
              Total due now will be charged immediately.
            </p>
          </h4>
          <h4 className="top">
            TOTAL ANNUAL RECURRING
            <NumberFormat
              displayType="text"
              prefix={currency.symbol}
              thousandSeparator={true}
              decimalScale={2}
              value={subTotals.recurring}
            />
          </h4>

          <p className="fine-print">
            Applicable VAT, sales or other applicable taxes may apply.
          </p>
        </Col>
        <StripeIntegration
          onPayWithInvoice={null}
          subscriptionType="Deal"
          subCurrency={currency.code}
          subQuantity={seatQuantity}
          subDiscountCode={null}
          totalPrice={total}
        />
      </Row>
    );
  }

  private subTotal = () => {
    const subTotals = this.props.config.priceConfig.lineItems.reduce(
      (res, item) => {
        const cost = item.quantity * item.price;
        if (item.revenueType === "recurring") {
          res.recurring += cost;
        } else {
          res.onetime += cost;
        }
        return res;
      },
      {
        recurring: 0,
        onetime: 0
      }
    );

    return subTotals;
  };

  private subQuantityCalc = () => {
    const seatQuantity = this.props.config.priceConfig.lineItems.reduce(
      (seatsCount, item) => {
        seatsCount.seats += item.quantity;
        return seatsCount;
      },
      {
        seats: 0
      }
    );

    return seatQuantity;
  };
}

export const DealOrderFormComponent = withConfig(OrderForm);
