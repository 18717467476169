import { Checkbox, Col, Icon, Row } from "antd";
import * as css from "./CheckoutForm.pcss";
import { classNames, FlipMove } from "@src/components";
import * as React from "react";
import * as queryString from "query-string";
import {
  CardElement,
  injectStripe,
  ReactStripeElements
} from "react-stripe-elements";
import { SUBSCRIPTION_TYPE } from "@src/constants";
import {
  Variant,
  withConfig,
  WithConfigProp,
  StandardConfigStore
} from "@src/stores/config";
import { CurrencyType } from "@src/services/pricing";
import { SubRequest, subscribe } from "@src/services/subscribe";
import { CustomerInfo, CustomerInfoFormComponent } from "./CustomerInfoForm";
import ElementChangeResponse = stripe.elements.ElementChangeResponse;
import { TOS } from "../tos";

interface CheckoutFormProps extends ReactStripeElements.InjectedStripeProps {
  onPayWithInvoice: () => void;
  subscriptionType: SUBSCRIPTION_TYPE;
  subQuantity: number;
  subDuration?: number;
  subDiscountCode: string;
  subCurrency: CurrencyType;
  totalPrice: number;
}

interface CheckoutFormState extends CustomerInfo {
  customerInfoComplete: boolean;
  errorMessage: string;
  isSubmittingOrder: boolean;
  stripeComplete: boolean;
  visited: { [P in keyof CustomerInfo | "stripe"]?: boolean };
  orderPlaced: boolean;
}

export class CheckoutForm extends React.Component<
  CheckoutFormProps & WithConfigProp,
  CheckoutFormState
> {
  constructor(props: CheckoutFormProps & WithConfigProp) {
    super(props);
    this.state = {
      name: "",
      email: "",
      company: "",
      phone: "",
      taxId: "",
      state: undefined,
      zipcode: "",
      city: "",
      street: "",
      countryCode: "us",
      customerInfoComplete: false,
      isSubmittingOrder: false,
      visited: {},
      stripeComplete: false,
      errorMessage: null,
      orderPlaced: false
    };
  }

  public onCardElementChange = (e: ElementChangeResponse) => {
    this.setState({ stripeComplete: e.complete });
  };

  public setVisited = (key: keyof CustomerInfo | "stripe") => {
    this.setState({
      visited: { ...this.state.visited, ...{ [key]: true } }
    });
  };

  public setCustomerInfo = <K extends keyof CustomerInfo, V = CustomerInfo[K]>(
    key: keyof CustomerInfo,
    val: V
  ) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: val
    }));
  };

  public submitOrder = async () => {
    const {
      props: { subCurrency, stripe },
      state: { name, city, state, street, zipcode }
    } = this;

    // mark all as visited so all errors show up
    if (!(this.state.customerInfoComplete && this.state.stripeComplete)) {
      this.setState({
        visited: {
          name: true,
          email: true,
          company: true,
          taxId: true,
          street: true,
          city: true,
          state: true,
          zipcode: true,
          phone: true,
          stripe: true
        }
      });
      return;
    }

    if (this.state.isSubmittingOrder || this.state.orderPlaced) {
      return;
    }

    this.setState(
      {
        isSubmittingOrder: true,
        errorMessage: null
      },
      async () => {
        try {
          const tokenResult = await stripe.createToken({
            name,
            address_line1: street,
            address_city: city,
            address_state: state,
            address_zip: zipcode,
            currency: subCurrency
          });
          if (!tokenResult || tokenResult.error) {
            this.setState({
              errorMessage: "There was an error submitting your request."
            });
            return;
          }

          const {
            state: { email, company, phone },
            props: {
              subscriptionType,
              subQuantity,
              subDuration,
              subDiscountCode
            }
          } = this;

          const reqData: SubRequest = {
            leadId: queryString.parse(location.search).id,
            name,
            email,
            company,
            phone,
            address: {
              street,
              city,
              state,
              zipcode,
              countryCode: this.state.countryCode
            },
            type: subscriptionType,
            quantity: subQuantity,
            duration: subscriptionType === "Annual" ? 12 : subDuration,
            currencyCode: subCurrency,
            discountCode:
              subscriptionType === "Annual" ? subDiscountCode : null, // only allow discounts for annual subs
            stripePaymentToken: tokenResult.token.id,
            totalPrice: this.props.totalPrice,
            variant: this.props.config.variant,
            taxId: this.state.taxId,
            subscriptionReferenceId:
              this.props.config.variant === Variant.EXPAND ||
              this.props.config.variant === Variant.RENEW ||
              this.props.config.variant === Variant.DEAL
                ? this.props.config.priceConfig.subscriptionReferenceId
                : null
          };

          await subscribe(reqData);
          this.setState({
            orderPlaced: true
          });
        } catch (error) {
          // console.log("Axios Error", error);
          this.setState({
            errorMessage: error.message
              ? error.message
              : "There was an error submitting your request."
          });
        } finally {
          this.setState({ isSubmittingOrder: false });
        }
      }
    );
  };

  public render() {
    const {
      onCardElementChange,
      state: { errorMessage, orderPlaced, stripeComplete, isSubmittingOrder },
      props: { subscriptionType, config }
    } = this;

    const canSubmitOrder =
      this.state.customerInfoComplete &&
      this.state.stripeComplete &&
      !orderPlaced;
    const disableInput = this.state.orderPlaced || this.state.isSubmittingOrder;
    const showState =
      this.state.countryCode === "us" || this.state.countryCode === "ca";

    // const showInvoiceOption =
    //   this.props.config.variant == Variant.RENEW ? true : false;
    const showInvoiceOption = false;

    return (
      <form className={classNames(`stripe-form`, css.root)}>
        <h3>
          Pay By Card
          {showInvoiceOption && (
            <span style={{ float: "right", fontSize: 14 }}>
              <a onClick={this.props.onPayWithInvoice}>Pay with Invoice</a>
            </span>
          )}
        </h3>
        <CustomerInfoFormComponent
          name={this.state.name}
          email={this.state.email}
          company={this.state.company}
          phone={this.state.phone}
          countryCode={this.state.countryCode}
          street={this.state.street}
          city={this.state.city}
          state={this.state.state}
          zipcode={this.state.zipcode}
          taxId={this.state.taxId}
          disable={disableInput}
          visited={this.state.visited}
          onChange={this.setCustomerInfo}
          onVisited={this.setVisited}
          onCompleteChange={complete =>
            this.setState({ customerInfoComplete: complete })
          }
          showState={showState}
        />
        <Row className={classNames("form-row", css["gift-row"])}>
          <FlipMove>
            <Col
              span={24}
              aria-disabled={disableInput}
              className={classNames({
                [css.error]: this.state.visited["stripe"] && !stripeComplete
              })}
            >
              <CardElement
                onBlur={() => this.setVisited("stripe")}
                onChange={onCardElementChange}
                style={{
                  base: {
                    fontSize: "18px",
                    color: orderPlaced ? "rgba(0, 0, 0, 0.25)" : undefined
                  }
                }}
              />
            </Col>
          </FlipMove>
        </Row>
        <Row className={classNames("form-row")}>
          <Col span={24}>
            <a
              className={classNames(css["order-button"], subscriptionType, {
                [css["order-placed"]]: orderPlaced
              })}
              aria-disabled={!canSubmitOrder}
              onClick={this.submitOrder}
            >
              {orderPlaced ? "Your Order has Been Placed!" : "Submit Order"}
              {isSubmittingOrder && (
                <Icon type="sync" className={css.loader} spin={true} />
              )}
            </a>
          </Col>
          <Col span={24}>
            <FlipMove>
              {errorMessage && (
                <span className={css.errorMessage}>{errorMessage}</span>
              )}
            </FlipMove>
          </Col>
          <Col span={24}>
            <TOS
              config={this.props.config}
              paymentType="cc"
              subscriptionType={subscriptionType}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export const CheckoutFormComponent = injectStripe(withConfig(CheckoutForm));
