import * as React from 'react';

import logoImg from '@src/assets/images/logo.png';
import { Row } from 'antd';

export const HeaderComponent: React.SFC = (props) => {
  return (
    <div>
      <Row className='header'>
        <a href='http://headspace.com'><img src={logoImg} alt='headspace-logo' className='headspace-logo'/></a>
        <a href='#subscribe' className='subscribe-now'>Purchase Now</a>
      </Row>
    </div>
  );
};
