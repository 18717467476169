import { Col, Row } from "antd";
import * as React from "react";

import engageImg from "@src/assets/images/hs_analyzing_data.png";
import { withConfig, WithConfigProp, Variant } from "@src/stores/config";

interface EngageProps {}

class Engage extends React.Component<EngageProps & WithConfigProp, {}> {
  public render() {
    if (
      this.props.config.variant === Variant.EXPAND ||
      this.props.config.variant === Variant.RENEW
    ) {
      return null;
    }

    return (
      <Row className="purple-theme">
        <div className="icon-content">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <div className="paragraph-content offset-top">
              <h2>Engage</h2>
              <ul>{this.getPoints()}</ul>
            </div>
          </Col>
          <Col xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 0 }}>
            <img src={engageImg} alt="engage" style={{ width: "100%" }} />
          </Col>
        </div>
      </Row>
    );
  }

  public getPoints = () => {
    switch (this.props.config.variant) {
      case Variant.NON_PROFIT:
        return [
          <li key="1">Helpful implementation tools and guidance</li>,
          <li key="2">
            Quarterly reporting on minutes and sessions meditated, popular
            packs, and more
          </li>,
          <li key="3">
            Online resources including our Science page, FAQs, and animations
          </li>
        ];
      default:
        return [
          <li key="1">Headspace newsletter to support ongoing engagement</li>,
          <li key="2">Headspace Science education and product webinars</li>
        ];
    }
  };
}

export const EngageComponent = withConfig(Engage);
