import * as React from "react";
import classNames from "classnames";
import * as css from "./OrderForm.pcss";
import { Input, Checkbox } from "antd";
import {
  CurrencyType,
  Discount,
  getDiscountPrice
} from "@src/services/pricing";
import * as queryString from "query-string";
import { withConfig, WithConfigProp, Variant } from "@src/stores/config";

export interface DiscountCodeProps {
  allowDiscount: boolean;
  currentDiscount: Discount;
  currencySymbol: string;
  currency: CurrencyType;
  onDiscountChanged: (discount: Discount) => void;
}

export interface DiscountCodeState {
  discountCode: string;
  showDiscountInput: boolean;
  discountError: string;
}

class DiscountCode extends React.Component<
  DiscountCodeProps & WithConfigProp,
  DiscountCodeState
> {
  constructor(props) {
    super(props);
    this.state = {
      discountCode: "",
      discountError: null,
      showDiscountInput: false
    };
  }

  private handleSubmit = async () => {
    this.props.onDiscountChanged(null);
    this.setState({ discountError: null }, async () => {
      try {
        // const discount = await getDiscountPrice({
        //   leadId: queryString.parse(location.search).id,
        //   discountCode: this.state.discountCode,
        //   currency: this.props.currency,
        //   variant: this.props.config.variant === Variant.EXPAND ? this.props.config.priceConfig.originalVariant : this.props.config.variant,
        // });
        // this.props.onDiscountChanged(discount);
        this.props.onDiscountChanged(0);
      } catch (error) {
        console.log("Discount Error", error);
        const msg = error.message
          ? error.message
          : "There was an issue processing your request. Please contact support!";

        this.setState({
          discountError: msg
        });
      }
    });
  };

  private getDiscountMessage = () => {
    const { discountError } = this.state;
    const { currentDiscount: discount } = this.props;
    let msg = null;
    if (discountError != null) {
      msg = discountError;
    } else if (discount) {
      switch (discount.type) {
        case "FIXED":
          msg =
            this.props.currencySymbol + discount.amount + " discount applied";
          break;
        case "PERCENT":
          msg = discount.amount + "% discount applied";
          break;
        case "PER_SEAT":
          msg =
            this.props.currencySymbol +
            discount.amount +
            " per seat discount applied";
          break;
      }
    }

    if (msg) {
      return <div style={{ textAlign: "right", color: "red" }}>{msg}</div>;
    }

    return null;
  };

  public render() {
    if (!this.props.allowDiscount) {
      return null;
    }

    let body: JSX.Element = null; // either a checkbox to an input
    if (this.state.showDiscountInput) {
      body = (
        <h4 key="discountCode" className="middle">
          Discount Code
          <span>
            <Input
              size="large"
              className={classNames(css.input, "form-row")}
              type="text"
              id="discountCode"
              name="discount"
              value={this.state.discountCode}
              onChange={v => {
                if (v) {
                  this.setState({ discountCode: v.target.value });
                }
              }}
              onBlur={this.handleSubmit}
            />
            <button
              type="button"
              onClick={this.handleSubmit}
              className={classNames(css["discount-code-button"], "Annual")}
            >
              Apply
            </button>
          </span>
        </h4>
      );
    } else {
      body = (
        <h4 key="discountCodeCheckbox" className="middle">
          Have a Discount Code?
          <span>
            <Checkbox
              className={classNames(css.input, "form-row")}
              onChange={() => this.setState({ showDiscountInput: true })}
            />
          </span>
        </h4>
      );
    }
    return (
      <div>
        {body}
        {this.getDiscountMessage()}
      </div>
    );
  }
}

export const DiscountCodeComponent = withConfig(DiscountCode);
