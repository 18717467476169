import { Col, Row } from "antd";
import * as React from "react";

import engageImg from "@src/assets/images/hs_analyzing_data.png";
import { withConfig, WithConfigProp, Variant } from "@src/stores/config";

export default function ErrorComponent() {
  return (
    <Row className="purple-theme">
      <div className="icon-content">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <div>
            <h2>Error</h2>
            <p>
              Thanks for visiting - we’re excited to help you and your business
              to improve health and happiness! <br />
              <br />
              It looks like there is a problem with the link you’re using. If
              you’re trying to renew or add users to an existing subscription,
              please reach out to your Headspace representative and they can
              help you find an up-to-date link.
            </p>
            {/* <ul>{this.getPoints()}</ul> */}
          </div>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 0 }}>
          <img src={engageImg} alt="engage" style={{ width: "100%" }} />
        </Col>
      </div>
    </Row>
  );
}
// }

// export const ErrorComponent = withConfig(Error);
