import { SUBSCRIPTION_TYPE } from '@src/constants';
import { CurrencyType, PriceData } from '@src/services/pricing';
import { Variant, withConfig, WithStandardConfigProp } from '@src/stores/config';
import { Col, Row } from 'antd';
import * as queryString from 'query-string';
import classNames from 'classnames';
import * as React from 'react';
import { OrderFormComponent } from './OrderForm';
import * as css from './Purchase.pcss';

interface SubscriptionProps {}

interface SubscriptionState {
  selectedSubscriptionType: SUBSCRIPTION_TYPE;
  selectedCurrency: CurrencyType;
}

class Subscription extends React.Component<SubscriptionProps & WithStandardConfigProp, SubscriptionState> {

  constructor(props: SubscriptionProps & WithStandardConfigProp) {
    super(props);
    const defaultSubType = () : SUBSCRIPTION_TYPE =>{
        let type : SUBSCRIPTION_TYPE;
        const isMonthly = queryString.parse(location.search).monthly;
        console.log(isMonthly);
        if(isMonthly){
          type = 'Monthly';
        } else {
          type = props.config.priceConfig.priceLists[CurrencyType.USD].annualPricePerSeat ? 'Annual' : props.config.priceConfig.priceLists[CurrencyType.USD].monthlyPricePerSeat ? 'Monthly' : 'Weekly';
        }
        return type;
      }

    this.state = {
      selectedSubscriptionType: defaultSubType(),
      selectedCurrency: CurrencyType.USD,
    };

  }

  public render() {

    return (
      <Row id='subscribe' className='tan-theme'>
        <h1>Unlock the Headspace Library</h1>
        {this.renderBody()}
      </Row>
    );
  }

  private renderBody = () => {
    const { selectedCurrency, selectedSubscriptionType } = this.state;
    const priceInfo = this.props.config.priceConfig.priceLists[selectedCurrency];

    return(
      <React.Fragment>
        <Col>
          <Row type='flex' gutter={2} justify='center'>
            {this.renderWeekly(priceInfo)}
            {this.renderMonthly(priceInfo)}
            {this.renderAnnual(priceInfo)}
          </Row>
        </Col>

        <Col span={24}>
          <OrderFormComponent
            subscriptionType={selectedSubscriptionType}
            symbol={priceInfo.symbol}
            price={this.subPrice(selectedSubscriptionType, priceInfo)}
            currency={selectedCurrency}
            handleCurrencyChange={this.handleCurrencyChange}
          />
        </Col>
      </React.Fragment>
    );
  };

  private subPrice = (selectedSubscriptionType: SUBSCRIPTION_TYPE, priceInfo: PriceData) => {
    if (selectedSubscriptionType === 'Annual') {
      return priceInfo.annualPricePerSeat;
    } else if (selectedSubscriptionType === 'Monthly') {
      return priceInfo.monthlyPricePerSeat;
    } else if (selectedSubscriptionType === 'Weekly') {
      return priceInfo.weeklyPricePerSeat;
    } else {
      return null
    }
  };

  private renderWeekly = (priceInfo: PriceData) => {
    if (!priceInfo.weeklyPricePerSeat) {
      return null;
    }

    const mostPopularShown = !priceInfo.annualPricePerSeat;

    return (
      <Col className='subscription'>
        <div className={classNames(css['subscription-wrapper'], css.monthly, { [css.selected]: this.state.selectedSubscriptionType === 'Weekly' })}>
          {this.props.config.variant !== Variant.PILOT && <span className={css['subscription-title']}>{'Weekly'}</span>}
          {this.props.config.variant === Variant.PILOT && <span className={css['subscription-title']}>{'Pilot'}</span>}
          <span className={css['subscription-price']}>{priceInfo.symbol}{priceInfo.weeklyPricePerSeat}<span>/week*</span></span>
          <div>
            <button
              className={classNames(css['subscribe-button'], 'Weekly')}
              onClick={(e) => this.handleSubTypeChange('Weekly')}
            >
              Purchase Now
            </button>
          </div>
          <p className='small-text'>*Billed as one payment</p>
        </div>
      </Col>
    );
  };

  private renderMonthly = (priceInfo: PriceData) => {
    if (!priceInfo.monthlyPricePerSeat) {
      return null;
    }

    return (
      <Col className='subscription'>
        <div className={classNames(css['subscription-wrapper'], css.monthly, css['no-most-popular'], { [css.selected]: this.state.selectedSubscriptionType === 'Monthly' })}>
          {this.props.config.variant !== Variant.PILOT && <span className={css['subscription-title']}>{'Monthly'}</span>}
          {this.props.config.variant === Variant.PILOT && <span className={css['subscription-title']}>{'Pilot'}</span>}
          <span className={css['subscription-price']}>{priceInfo.symbol}{priceInfo.monthlyPricePerSeat}<span>/mo*</span></span>
          <div>
            <button
              className={classNames(css['subscribe-button'], 'Monthly')}
              onClick={(e) => this.handleSubTypeChange('Monthly')}
            >
              Purchase Now
            </button>
          </div>
          <p className='small-text'>*Billed as one payment</p>
        </div>
      </Col>
    );
  };

  private renderAnnual = (priceInfo: PriceData) => {
    if (!priceInfo.annualPricePerSeat) {
      return null;
    }

    return (
      <Col className={classNames(css.subscription)}>
        <div className={classNames(css['subscription-wrapper'], css['no-most-popular'], css.annual, { [css.selected]: this.state.selectedSubscriptionType === 'Annual' })}>
          {/*<span className={css.character}/> */}
          <span className={css['subscription-title']}>Annual</span>
          <span className={css['subscription-price']}>{priceInfo.symbol}{priceInfo.annualPricePerSeat}<span>/yr*</span></span>
          <div>
            <button
              className={classNames(css['subscribe-button'], 'Annual')}
              onClick={(e) => this.handleSubTypeChange('Annual')}
            >
              Purchase Now
            </button>
          </div>
          <p className='small-text'>*Billed as one payment</p>
          <p className='small-text'>Recurring annual payment</p>
        </div>
      </Col>
    );
  };

  private handleSubTypeChange = (selectedSubscriptionType: SUBSCRIPTION_TYPE) => {
    this.setState({
      selectedSubscriptionType,
    });
  };

  private handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedCurrency: e.target.value as CurrencyType,
    });
  }
}

export const PurchaseComponent = withConfig(Subscription);
